<template>
  <div
    v-if="!houseUrl"
    class="pn-sa pn-sa__sa"
  >
    <div class="container">
      <div class="pn-sa__form d-flex relative">
        <div class="pn-sa__form-wrap">
          <p class="pn-sa__form-title headline-2">
            Проверьте подключение вашего дома к сети
            {{ provider.provider.name }}
          </p>
          <button
            v-if="getAddressText?.cityOnly"
            data-test="city-change-find-out-form"
            class="pn-sa__form-city d-flex align-center"
            @click="showDialogCity"
          >
            <img
              loading="lazy"
              src="@/assets/images/svg/pin-thin_white.svg"
              class=""
              alt="pin"
              style="width: 24px; height: 24px;"
            >{{ getAddressText.cityOnly }}
          </button>
          <div
            data-test="search-by-address-find-out-form"
            class="pn-sa__form-input d-flex align-center"
            @click="showModalAddress"
          >
            <span
              class="pn-sa__form-pin"
            ><svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 36.3133C22.7444 34.9501 23.5705 33.6372 24.4783 32.3745C25.386 31.1118 26.3031 29.9143 27.2297 28.782C28.7983 26.8242 30.0424 25.108 30.9619 23.6335C31.8813 22.1591 32.3411 20.238 32.3411 17.8702C32.3411 15.115 31.3346 12.765 29.3216 10.8202C27.3086 8.87546 24.8642 7.90307 21.9882 7.90307C19.1123 7.90307 16.6718 8.87546 14.6666 10.8202C14.861 6.02944 25.295 6.55895 30.2374 9.736C30.2374 12.1037 32.0637 14.0861 32.9832 15.5606C33.9027 17.0351 30.8654 22.0749 32.434 24.0327C33.3606 25.165 22.7986 34.4192 23.7063 35.6819C24.614 36.9446 22 36.7409 22 36.3133ZM22 40.3333C21.617 40.3333 21.2713 40.2172 20.963 39.985C20.6546 39.7528 20.4342 39.448 20.3018 39.0707C19.3669 36.6431 18.2647 34.6262 16.9952 33.02C15.7258 31.4139 14.5117 29.8749 13.3529 28.4031C12.1785 26.945 11.1872 25.4182 10.379 23.8229C9.57077 22.2276 9.16666 20.244 9.16666 17.872C9.16666 14.414 10.4053 11.4875 12.8825 9.0925C15.3598 6.6975 18.3989 5.5 22 5.5C25.601 5.5 28.6402 6.6943 31.1175 9.08292C33.5947 11.4715 34.8333 14.4019 34.8333 17.8741C34.8333 20.2447 34.4315 22.2248 33.6279 23.8145C32.8243 25.4042 31.8378 26.9337 30.6682 28.4031C29.4939 29.8749 28.2724 31.4139 27.0037 33.02C25.735 34.6262 24.6358 36.6384 23.7063 39.0565C23.5685 39.4271 23.3454 39.7326 23.037 39.9729C22.7287 40.2132 22.383 40.3333 22 40.3333Z"
                fill="currentColor"
              />
              <circle
                cx="22"
                cy="18.332"
                r="5.5"
                fill="white"
              />
            </svg></span>
            <p>Введите улицу и дом</p>
            <button class="d-flex align-center">
              <span>Найти тарифы</span>
              <span
                class="mask-icon mask-size-lg mask-search-thin"
              />
            </button>
          </div>
        </div>
        <div class="pn-sa__form-cb d-flex flex-column">
          <p>
            или закажите звонок, наш специалист перезвонит в течение 10 минут
          </p>
          <button
            class="pn-sa__form-btn d-flex align-center"
            @click="showDialogCallPartner"
          >
            <span class="mask-icon mask-size-lg mask-phone-thin" />Заказать звонок
          </button>
        </div>
        <div
          v-if="loadingProviders"
          class="pn-sa__loading"
        >
          <svg
            viewBox="25 25 50 50"
          >
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="providersIds && providersIds.includes(provider.provider.id)"
    class="pn-sa pn-sa__available"
  >
    <div class="container">
      <div class="pn-sa__available__wrap relative">
        <p class="pn-sa__available-title headline-4">
          {{ getAddressText.fullAddress }}
          <button
            class="d-inline-flex mask-size-lg mask-pencil-new mask-icon secondary-dark--text"
            @click="showModalAddress"
          />
        </p>
        <p class="pn-sa__available-text title-2">
          <span
            class="additional-light--bg white--text d-flex pn-sa__available-icon"
          ><span class="mask-icon mask-ischecked mask-size-lg" /></span>Подключение доступно
        </p>
        <p class="pn-sa__available-descr title-5">
          В вашем доме сейчас проводится акция. Для получения подробностей
          оставьте контактные данные
        </p>
        <form
          action="#"
          autocomplete="off"
          class="pn-sa__available-form d-flex"
          @submit.prevent="submit"
        >
          <LazyVPhoneInput
            ref="dPhone"
            v-model:value="phoneNumber"
            @validate="checkPhoneValidation($event)"
          />
          <d-btn
            :loading="loading"
            :disabled="!isValidPhone"
            class=""
            color="custom"
            size="medium"
            @click.prevent="submit"
          >
            Отправить
          </d-btn>
          <input
            style="
              opacity: 0;
              max-height: 0;
              max-width: 0;
              border: none;
              padding: 0;
              order: 10000;
            "
            type="submit"
          >
        </form>
        <p class="pn-sa__available-confid subhead-6">
          <span>Отправляя, вы соглашаетесь </span>с
          <button
            class="text-underline"
            @click="goAway('/legal/privacy/')"
          >
            Политикой обработки данных
          </button>
        </p>
        <img
          loading="lazy"
          class="pn-sa__available-img"
          :src="
            imgUrl(
              'https://api.dominter.net/media/uploads/blog/box.png',
              464,
              0,
            )
          "
          alt=""
        >
        <div
          v-if="loadingProviders"
          class="pn-sa__loading"
        >
          <svg
            viewBox="25 25 50 50"
          >
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="providersIds && providersIds.length"
    class="pn-sa pn-sa__notavailable"
  >
    <div class="container">
      <div class="pn-sa__notavailable__wrap relative">
        <p class="pn-sa__notavailable-title headline-5">
          <span class="pn-sa__notavailable-icon red--bg d-flex align-center justify-center">
            <img
              loading="lazy"
              src="@/assets/images/svg/cancel_white.svg"
              alt="cancel"
            >
          </span>Автоматическая проверка не удалась
        </p>
        <p class="pn-sa__notavailable-text title-2">
          {{ getAddressText.fullAddress }}
          <button
            class="d-inline-flex mask-size-lg mask-pencil-new mask-icon secondary-dark--text"
            @click="showModalAddress"
          />
        </p>
        <p class="pn-sa__notavailable-maintext">
          ЗАЯВКА НА РУЧНУЮ ПРОВЕРКУ ДОСТУПНОСТИ ПРОВАЙДЕРОВ
        </p>
        <p class="pn-sa__notavailable-descr title-2">
          Наш специалист проведёт дополнительную проверку и расскажет вам о
          доступных вариантах
        </p>
        <form
          action="#"
          autocomplete="off"
          class="pn-sa__notavailable-form d-flex"
          @submit.prevent="submit"
        >
          <LazyVPhoneInput
            ref="dPhone"
            v-model:value="phoneNumber"
            @validate="checkPhoneValidation($event)"
          />
          <LazyVTextInput
            ref="dName"
            v-model:value="clientName"
            :validation-length="0"
            enterkeyhint="send"
            tabindex="2"
          />
          <d-btn
            :loading="loading"
            :disabled="!isValidPhone"
            class=""
            color="custom"
            size="medium"
            @click.prevent="submit"
          >
            Жду звонка
          </d-btn>
          <input
            style="
              opacity: 0;
              max-height: 0;
              max-width: 0;
              border: none;
              padding: 0;
              order: 10000;
            "
            type="submit"
          >
          <LazyVMenu
            content-class="white--bg"
            @update:model-value="showActiveSortList"
          >
            <template #activator="{ props }">
              <span
                v-bind="props"
                class="pn-sa__menu pointer d-flex align-center white--text title-5"
              >
                Перезвоним {{ timeToCall }}
                <img
                  :class="{ rotate: props['aria-expanded'] === 'true' }"
                  style="transition: transform 0.24s; width: 20px; height: 20px;"
                  loading="lazy"
                  src="@/assets/images/svg/arrow-expand.svg"
                  alt="arrow"
                >
              </span>
            </template>
            <LazyVList elevation="4">
              <LazyVListItem
                :value="timeNow"
                :disabled="timeNow.text === timeToCall"
                @click="setTimeToCall(timeNow)"
              >
                <span class="subhead-3">{{ timeNow.text }}</span>
              </LazyVListItem>
              <LazyVListItem
                v-for="item in timeList"
                :key="item.val"
                :disabled="item.text === timeToCall"
                @click="setTimeToCall(item)"
              >
                <span class="subhead-3">{{ item.text }}</span>
              </LazyVListItem>
            </LazyVList>
          </LazyVMenu>
        </form>
        <p class="pn-sa__notavailable-confid subhead-6">
          <span>Отправляя, вы соглашаетесь </span>с
          <button
            class="text-underline"
            @click="goAway('/legal/privacy/')"
          >
            Политикой обработки данных
          </button>
        </p>
        <div
          v-if="loadingProviders"
          class="pn-sa__loading"
        >
          <svg
            viewBox="25 25 50 50"
          >
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="pn-sa pn-sa__available"
  >
    <div class="container">
      <div class="pn-sa__available__wrap relative">
        <p class="pn-sa__available-title headline-4">
          {{ getAddressText.fullAddress }}
          <button
            class="d-inline-flex mask-size-lg mask-pencil-new mask-icon secondary-dark--text"
            @click="showModalAddress"
          />
        </p>
        <p class="pn-sa__available-text title-2">
          <span class="pn-sa__available-icon red--bg d-flex align-center justify-center">
            <img
              loading="lazy"
              src="@/assets/images/svg/cancel_white.svg"
              alt="cancel"
            >
          </span>Отсутствует техническая возможность подключения
        </p>
        <p class="pn-sa__available-descr title-5">
          Попробуйте изменить адрес
        </p>
        <d-btn
          class=""
          color="custom"
          size="medium"
          @click.prevent="showModalAddress"
        >
          Изменить адрес
        </d-btn>

        <div
          v-if="loadingProviders"
          class="pn-sa__loading"
        >
          <svg
            viewBox="25 25 50 50"
          >
            <circle
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { createInteraction } from '~/composible/createInteraction'
import { goAway } from '~/helpers'
import { callTimeComposible } from '~/composible/callTimeComposible'
import { useCities } from '~/store/cites/cities.store'

const props = defineProps({
  providersIds: {},
  loadingProviders: {},
})

const ctx = useNuxtApp()
const $router = useRouter()
const tariffsStore = useTariffs()
const mainStore = useMainStore()
const cityStore = useCities()
const houseUrl = computed(() => cityStore.getHouseUrl)
const provider = computed(() => mainStore.getDomainConfig)
const getAddressText = computed(() => cityStore.getAddressText)
const phoneNumber = ref('')
const clientName = ref('')
const dPhone = ref()
const dName = ref()
const loading = ref(false)
const isValidPhone = ref(false)
const {
  timeList,
  timeNow,
  timeToCall,
  callTime,
  setTimeToCall,
  showActiveSortList,
} = callTimeComposible()

const showModalAddress = () => {
  ctx.$event('addressDialog', { redirect: false, label: 'offer' })
}
const showDialogCity = () => {
  ctx.$event('cityDialog', { redirect: true, label: 'offer' })
}
const showDialogCallPartner = () => {
  if (houseUrl.value) ctx.$event('callDialog', 'Заказать звонок')
  else ctx.$event('callDialogPartner')
}

const imgUrl = (path: string, w?: number, h?: number) => {
  const url = ctx.$thumborURL.setImagePath(path).resize(w, h).buildUrl()
  return url
}

const checkPhoneValidation = (event) => {
  isValidPhone.value = event

  if (event) {
    nextTick(() => {
      dName.value?.inputRef?.focus()
    })
  }
}

const { cI } = createInteraction()
const submit = async () => {
  if (!isValidPhone.value) {
    dPhone.value.onBlur()
    return
  }
  loading.value = true

  const tariffS = {}

  const lead = {
    phone: '+7' + phoneNumber.value.replace(/[\-(\s)]/g, ''),
    first_name: clientName.value,
    call_time: !props.providersIds?.includes(provider.value.provider.id)
      ? callTime.value
      : undefined,
  }
  tariffsStore.$patch({
    clientPhone: '+7' + phoneNumber.value,
    clientName: clientName.value,
    timeToCall: timeToCall.value,
  })

  const ciResp = await cI(!!houseUrl.value, lead, tariffS)
  tariffsStore.$patch({
    orderId: ciResp,
    interactionId: ciResp,
  })
  loading.value = false

  nextTick(() => {
    $router.push({ path: '/complete/' })
  })
}

onMounted(async () => {
  await mainStore.fetchCallSlots()
  timeToCall.value = timeNow.value.text
})
</script>

<style scoped lang="scss">
.pn-sa {

  .mask-pencil-new {
    mask-image: url('@/assets/images/svg/pencil-new.svg');
  }
  .mask-phone-thin {
    mask-image: url('@/assets/images/svg/phone-thin.svg');
  }
  .mask-ischecked {
    mask-image: url('@/assets/images/svg/ischecked.svg');
  }
  .mask-pin_large {
    mask-image: url('@/assets/images/svg/pin_large.svg');
  }
  .mask-search-thin {
    mask-image: url('@/assets/images/svg/search-thin.svg');
  }
  &__sa {
    padding-top: 32px;
    padding-bottom: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  &__loading {
    color: var(--mainColor);
    background: var(--bg_form);
    border-radius: 16px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    svg {
      animation: spin 1s infinite linear;
      width: 80px;
      height: 80px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 70px;
        height: 70px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 50px;
        height: 50px;
      }
    }
  }

  &__form {
    padding: 40px;
    background: var(--bg_form);
    color: var(--font_offer);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 32px 24px;
      border-radius: 12px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      padding: 20px 16px;
    }

    &-wrap {
      flex-grow: 1;
      @media (max-width: getBreakpoint(desktop)) {
        margin-bottom: 32px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 16px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-bottom: 12px;
      }
    }

    &-title {
      font-size: 32px;
      margin-bottom: 32px;
      max-width: 590px;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: 22px;
        margin-bottom: 24px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 21px;
      }
    }

    &-city {
      margin-bottom: 16px;
      transition: color 0.24s;
      color: var(--font_offer);
      img {
        margin-right: 8px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 16px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: fontSize(paragraph);
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(label);
      }

      &:hover {
        color: var(--mainColor);
      }
    }

    &-input {
      margin-right: 40px;
      height: 62px;
      border-radius: 12px;
      background-color: color(white);
      border: 1px solid color(gray-darker);
      color: color(black);
      padding-left: 20px;
      cursor: pointer;
      @media (max-width: getBreakpoint(tablet)) {
        border-radius: 8px;
        padding-left: 12px;
      }
      p {
        color: color(gray-dark);
      }
      button {
        margin-left: auto;
        height: 54px;
        background: var(--form_btn);
        margin-right: 4px;
        border-radius: 12px;
        padding: 16px 32px;
        font-size: fontSize(headline-8);
        font-weight: 500;
        color: var(--font_btn);
        .mask-icon {
          display: none;
        }
        @media (max-width: getBreakpoint(tablet)) {
          padding: 0;
          margin-right: 4px;
          border-radius: 8px;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          span:not(.mask-icon) {
            display: none;
          }
          .mask-icon {
            margin-right: 0;
            display: flex;
          }
        }
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 40px;
          height: 40px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 36px;
          height: 36px;
        }
      }

      @media (max-width: getBreakpoint(tablet)) {
        height: 56px;
        margin-right: 0;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 48px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 44px;
        padding-left: 16px;
        p {
          font-size: fontSize(paragraph);
        }
      }
    }
    &-pin {
      color: var(--form_btn);
      margin-right: 2px;
      margin-bottom: -5px;
      svg {
        width: 28px;
        height: 28px;
      }
    }

    &-cb {
      width: 360px;
      padding-left: 44px;
      border-left: 1px solid rgba(color(black), 0.2);
      align-items: flex-start;
      p {
        color: var(--font_offer);
        margin-bottom: 24px;
      }
      @media (max-width: getBreakpoint(desktop)) {
        width: 100%;
        padding-left: 0;
        border-left: none;
      }
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(paragraph);
        p {
          margin-bottom: 20px;
        }
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        p {
          font-size: 13px;
        }
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        p {
          font-size: fontSize(label);
        }
      }
    }
    &-btn {
      border: 1px solid var(--font_offer);
      height: 44px;
      border-radius: 8px;
      padding: 8px 16px;
      color: var(--font_offer);
      @media (max-width: getBreakpoint(tablet)) {
        height: 44px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 44px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px;
      }
    }
    @media (max-width: getBreakpoint(desktop)) {
      flex-direction: column;
    }
  }
  &__available {
    padding-top: 32px;
    padding-bottom: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    &__wrap {
      position: relative;
      background: var(--bg_form);
      color: var(--font_offer);
      padding: 40px;
      border-radius: 16px;
      @media (max-width: getBreakpoint(desktop)) {
        padding: 32px;
        border-radius: 12px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        padding: 32px 24px 12px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        padding: 32px 16px 12px;
      }
    }
    &-title {
      margin-bottom: 12px;
      button {
        position: relative;
        bottom: -3px;
        margin-left: 4px;
      }
    }
    &-text {
      position: relative;
      padding-left: 32px;
      margin-bottom: 24px;
    }
    &-icon {
      position: absolute;
      left: 0;
      top: -2px;
      border-radius: 50%;
      img, svg {
        width: 24px;
        height: 24px;
      }
    }
    &-descr {
      margin-bottom: 24px;
    }
    &-confid {
      @media (max-width: getBreakpoint(tablet)) {
        span {
          display: block;
        }
      }
      button {
        color: var(--font_offer);
        transition: color 0.24s;
        &:hover {
          color: var(--mainColor);
        }
      }
    }
    &-form {
      margin-bottom: 16px;
      gap: 12px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 64px;
        flex-direction: column;
        max-width: 400px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        margin-bottom: 56px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        margin-bottom: 48px;
      }
      &:deep(.p-input), &:deep(.t-input) {
        max-width: 280px;
        @media (max-width: getBreakpoint(tablet)) {
          max-width: unset;
        }
      }
    }
    &-img {
      position: absolute;
      right: 30px;
      bottom: 0;
      width: 328px;
      @media (max-width: getBreakpoint(desktop)) {
        width: 219px;
        right: 9px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        width: 165px;
        right: 4px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 153px;
        right: 4px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 137px;
        right: 4px;
      }
    }
    .d-btn {
      border-radius: 8px;
      padding: 8px 32px;
      height: 54px;
      background: var(--mainColor);
      @media (max-width: getBreakpoint(tablet)) {
        height: 44px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 42px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px;
      }
    }
  }
  &__notavailable {
    padding-top: 32px;
    padding-bottom: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    &__wrap {
      position: relative;
      background: var(--bg_form);
      color: var(--font_offer);
      padding: 40px 40px 12px;
      border-radius: 16px;
      @media (max-width: getBreakpoint(desktop)) {
        padding: 32px;
        border-radius: 12px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        padding: 24px 24px 12px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        padding: 24px 16px 12px;
      }
    }
    &-title {
      padding-left: 32px;
      position: relative;
      margin-bottom: 8px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 4px;
      }
      button {
        position: relative;
        bottom: -3px;
        margin-left: 4px;
      }
    }
    &-icon {
      position: absolute;
      left: 0;
      top: 3px;
      border-radius: 50%;
      img {
        width: 24px;
        height: 24px;
        @media (max-width: getBreakpoint(mobile-lg)) {
          width: 22px;
          height: 22px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          width: 20px;
          height: 20px;
        }
      }
    }
    &-maintext {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.3;
      margin-bottom: 8px;
      max-width: 700px;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: 22px;
        max-width: 600px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 21px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: 20px;
      }
    }
    &-text {
      position: relative;
      margin-bottom: 40px;
      color: rgba(color(white), 0.8);
      padding-left: 32px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 24px;
      }
    }
    &-descr {
      margin-bottom: 24px;
    }
    &-confid {
      text-align: end;
      @media (max-width: getBreakpoint(tablet)) {
        text-align: unset;
        span {
          display: block;
        }
      }
      button {
        color: var(--font_offer);
        transition: color 0.24s;
        &:hover {
          color: var(--mainColor);
        }
      }
    }
    &-form {
      margin-bottom: 4px;
      flex-wrap: wrap;
      gap: 12px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 24px;
        flex-direction: column;
        max-width: 400px;
      }
      &:deep(.p-input), &:deep(.t-input) {
        flex: 0 1 380px;
        @media (max-width: getBreakpoint(tablet)) {
          max-width: unset;
          flex: unset;
        }
      }
    }
    &-img {
      position: absolute;
      right: 30px;
      bottom: 0;
      width: 328px;
      @media (max-width: getBreakpoint(desktop)) {
        width: 219px;
        right: 9px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        width: 165px;
        right: 4px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 153px;
        right: 4px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 137px;
        right: 4px;
      }
    }
    .d-btn {
      border-radius: 8px;
      padding: 8px 32px;
      height: 54px;
      background: var(--mainColor);
      flex: 0 1 320px;
      @media (max-width: getBreakpoint(tablet)) {
        order: 2;
        flex: unset;
        height: 44px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        height: 42px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        height: 40px;
      }
    }
  }

  &__menu {
    margin-top: 12px;
    margin-right: auto;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 0;
      margin-bottom: 12px;
    }
  }

  &:deep(.d-phone__err:after) {
    color: #cb121d;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
